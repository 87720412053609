import {filter, get} from 'lodash';
import {v4 as uuidv4} from 'uuid';

import browserStorage from './browserStorage';
import initialState from '../reducers/initialState';

const token_key = 'rab_v1_token';
const user_key = 'rab_v1_user';
const locale_key = 'rab_v1_locale';
const screen_id_key = 'rab_v1_screen_id';
const locale_first_displayed_key = 'rab_v1_locale_display';
const manual_picked_location_key = 'rab_v1_manual_picked_location';
const pay_from_key = 'rab_v1_pay_from';
const showcase_user_token_key = 'rab_v1_showcase_user_token';

export const updateLocaleInHTML = (locale) => {
  const localeObj = get(filter(initialState.app.locale, {value: locale}), '[0]');
  const htmlEl = document.documentElement;

  if (localeObj && htmlEl) {
    htmlEl.setAttribute('lang', localeObj.value);
    htmlEl.setAttribute('dir', localeObj.direction);
    const classNames = filter(htmlEl.className.split(' '), className => className.indexOf('dir-') === -1);
    classNames.push(`dir-${localeObj.direction}`);
    htmlEl.className = classNames.join(' ');
  }
}

class Auth {

  constructor() {
    this.token = null;
    this.user = null;
    this.locale = null;
    this.localeSelectorDisplayed = null;
    this.manualPickedlocation = null;
  }

  isAuthenticated() {
    return !!this.token;
  }

  getToken() {
    if (this.token) {
      return this.token;
    }

    this.token = browserStorage.getItem(token_key);
    return this.token;
  }

  getUser() {
    if (this.user) {
      return this.user;
    }

    const user = browserStorage.getItem(user_key);
    try {
      this.user = JSON.parse(user);
      return this.user;
    } catch (e) {
      return null;
    }
  }

  getLocale() {
    if (this.locale) {
      return this.locale;
    }

    this.locale = browserStorage.getItem(locale_key);
    if (!this.locale) {
      this.setLocale(initialState.app.defaultLocale);
    } else {
      updateLocaleInHTML(this.locale);
    }

    return this.locale;
  }

  setLocale(locale) {
    this.locale = locale;
    browserStorage.setItem(locale_key, locale);
    // updateLocaleInHTML(this.locale);
  }

  getScreenId = () => {
    if (this.screenId) {
      return this.screenId;
    }

    this.screenId = browserStorage.getItem(screen_id_key);
    if (!this.screenId) {
      browserStorage.setItem(screen_id_key, uuidv4());
      return this.getScreenId();
    }
    return this.screenId;
  }

  getPayFrom() {
    let payFrom = browserStorage.getItem(pay_from_key);
    if (payFrom) {
      payFrom = JSON.parse(payFrom);
    }
    return payFrom;
  }

  setPayFrom(payFrom) {
    browserStorage.setItem(pay_from_key, JSON.stringify(payFrom));
  }

  deletePayFrom() {
    browserStorage.removeItem(pay_from_key);
  }

  isLocaleSelectorDisplayed() {
    if (this.localeSelectorDisplayed) {
      return this.localeSelectorDisplayed;
    }

    this.localeSelectorDisplayed = browserStorage.getItem(locale_first_displayed_key);
    return this.localeSelectorDisplayed;
  }

  setLocaleSelectorDisplayed() {
    this.localeSelectorDisplayed = true;
    browserStorage.setItem(locale_first_displayed_key, 1);
  }

  getManualPickedLocation() {
    if (this.manualPickedlocation) {
      return this.manualPickedlocation;
    }

    const location = browserStorage.getItem(manual_picked_location_key);
    try {
      this.manualPickedlocation = JSON.parse(location);
      return this.manualPickedlocation;
    } catch (e) {
      return null;
    }
  }

  setManualPickedLocation(location) {
    this.manualPickedlocation = location;
    browserStorage.setItem(manual_picked_location_key, JSON.stringify(location));
  }

  getShowcaseUserToken() {
    if (this.showcaseUserToken) {
      return this.showcaseUserToken;
    }

    this.showcaseUserToken = browserStorage.getItem(showcase_user_token_key);
    return this.showcaseUserToken;
  }

  setShowcaseUserToken(token) {
    this.showcaseUserToken = token;
    browserStorage.setItem(showcase_user_token_key, token);
  }

  setTokenUser(token, user) {
    this.token = token;
    this.user = user;

    browserStorage.setItem(token_key, token);
    browserStorage.setItem(user_key, JSON.stringify(user));
  }

  setUser(user) {
    this.user = user;

    browserStorage.setItem(user_key, JSON.stringify(user));
  }

  deleteUserToken() {
    this.token = null;
    this.user = null;
    this.showcaseUserToken = null;
    this.screenId = null;

    browserStorage.removeItem(token_key);
    browserStorage.removeItem(user_key);
    browserStorage.removeItem(showcase_user_token_key);
    browserStorage.removeItem(screen_id_key);
  }

}

const auth = new Auth();

(() => {
  const token = auth.getToken();
  const user = auth.getUser();
  auth.getLocale();
  if (token && user) {
    auth.setTokenUser(token, user);
  }
})();

export default auth;
