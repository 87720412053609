import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {hot} from 'react-hot-loader/root';

import Routes from './Routes';
import auth from '../utils/auth';
import {fetchProfile} from '../actions/profileActions'
import {checkForInstallPrompt} from "../utils/AppInstallHelper";
import {changeBodyColor, withMediaQueries} from "src/styles/Styles";
import {ColorBackground, ColorBackgroundXS} from "src/styles/Colors";
import {PathJoin, PathLogin} from "src/js/components/routes/NavHelper";
import LogRocket from 'logrocket';
import {APP_SHOWCASE, APP_TYPE, APP_TYPE_PROD} from "src/js/utils/services";
import {logDebug} from "src/js/utils/AppLog";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.backgroundColor = null;
    document.documentElement.style.setProperty('--recaptcha-hidden','hidden');

    this.state = {
      scale: 0,
      width: 0,
      height: 0,
    }
  }

  componentDidMount () {
    if (APP_TYPE === APP_TYPE_PROD) {
      LogRocket.init('kqnm2f/rab7');
    }
    if (auth.isAuthenticated()) {
      if (APP_TYPE === APP_TYPE_PROD) {
        LogRocket.identify(auth.user.id, {
          name: auth.user.rab7Number,
        });
      }
      if (!auth.user.isDigitalDisplay) {
        this.props.fetchProfile();
      }
    }

    checkForInstallPrompt();
    this.updateBgColor();
    this.adjustScale();
    window.addEventListener('resize', this.adjustScale);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustScale);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateBgColor();
  }

  adjustScale = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isPortrait = screenWidth < screenHeight;

    const maxSize = APP_SHOWCASE ? 1280 : Math.max(screenWidth, screenHeight);
    let targetWidth = 0;
    let targetHeight = 0;
    if (isPortrait) {
      targetHeight = maxSize;
      targetWidth = targetHeight/2;
    } else {
      targetWidth = maxSize;
      targetHeight = targetWidth/2;
    }

    // Calculate the scale factor based on width and height
    const scaleWidth = screenWidth / targetWidth;
    const scaleHeight = screenHeight / targetHeight;

    // Choose the smaller scaling factor to ensure the content fits in the viewport
    const newScale = Math.max(1, Math.min(scaleWidth, scaleHeight));
    const newWidth = Math.ceil(screenWidth / newScale);
    const newHeight = Math.ceil(screenHeight / newScale);
    logDebug('AppWindow', {scale: newScale, width: newWidth, height: newHeight, awidth: screenWidth, aheight: screenHeight});
    this.setState({scale: newScale, width: newWidth, height: newHeight});
    window.appWidth = newWidth;
    window.appHeight = newHeight;
  };

  updateBgColor = () => {
    const isXS = window.appWidth <= 600;
    let bgColor = ColorBackground;
    if (isXS) {
      bgColor = ColorBackgroundXS;
    } else {
      const path = location.pathname;
      if (path.startsWith(PathLogin)
        || path.startsWith(PathJoin)
        || path.startsWith('/recoverPassword')
        || path.startsWith('/verifyAccount')
        || path.startsWith('/redeemOffer')
        || path.startsWith('/account')) {
        bgColor = ColorBackgroundXS;
      }
    }
    if (this.backgroundColor !== bgColor) {
      this.backgroundColor = bgColor;
      changeBodyColor(this.backgroundColor);
    }
  }

  render() {
    const {app} = this.props;
    const {scale, width, height} = this.state;
    if (!scale) {
      return null;
    }

    let exStyle = null;
    if (scale !== 1) {
      exStyle = {
        transform: `scale(${scale})`,
        transformOrigin: app.isLTR ? 'top left' : 'top right',
        width: width,
        height: height,
      }
    }
    return (
      <div
        className='app-container'
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          ...exStyle
        }}>
        <Routes />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  fetchProfile: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    app: state.app,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(fetchProfile()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withMediaQueries([
    ['isXS', theme => theme.breakpoints.down('xs'), {defaultMatches: true}],
  ])(hot(App))
);
